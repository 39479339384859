import styled from "styled-components"
import WorkItemImage from "./WorkImage"
import { Typography } from "../../styleguide/typography"
import ConstrainedLayout from "../ConstrainedLayout"

const Icon = styled(WorkItemImage)`
  width: 30%;
  height: 30%;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const CenteredContainer = styled.div`
  text-align: center;
`

const H1 = styled(Typography.H1)`
  text-align: center;
`

const AppStoreButton = styled.a`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 140px;
`

const Layout = styled(ConstrainedLayout)`
  text-align: center;
`
interface WorkImageProps {
  width: string
  maxWidth: string
}
const WorkImage = styled(WorkItemImage)<WorkImageProps>`
  max-width: ${p => p.maxWidth};
  width: ${p => p.width};
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export default {
  AppStoreButton,
  H1,
  Icon,
  CenteredContainer,
  Layout,
  WorkImage,
}
