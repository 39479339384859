import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Icon = (props: any) => {
  const data = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "app_store_white.png" }) {
        childImageSharp {
          fixed(height: 50, width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const alt = "Icon for iOS App Store"

  return <Img fixed={data.icon.childImageSharp.fixed} alt={alt} {...props} />
}

export default Icon
