import React from "react"
import { graphql } from "gatsby"
import { Location } from "@reach/router"

import SEO from "../seo"
import workInfoHelper from "./WorkInfoHelper"
import WorkImage from "./WorkImage"
import { Typography } from "../../styleguide/typography"
const { H1, H2, H3, Paragraph } = Typography
import Style from "./WorkDetail.style"
import AppStoreIconWhite from "../images/IconAppStoreWhite"

interface IWorkImage {}

interface IWorkSection {
  heading?: string
  subheading?: string
  // images: IWorkImage[];
}

interface IWorkSectionProps {
  data: {
    projectsJson: {
      name: string
      id: string
    }
  }
}

// need to add image sizing, maybe even add an "icon" prop
// that will be sized to a smaller size and show with the title?

// const workDetail = (props: IWorkSectionProps) => {
//   // remove the forward slash
//   const { name, id } = props.data.projectsJson
//   const workInfo = workInfoHelper(id)
//   if (!workInfo) {
//     // should log this
//     return <></>
//   }
//   const appStore = workInfo.appStoreLink ? (
//     <Style.AppStoreButton href={workInfo.appStoreLink} target="_blank">
//       <AppStoreIconWhite />
//     </Style.AppStoreButton>
//   ) : null
//   const icon = <Style.Icon imageName={workInfo.imageName} />
//   const sections = workInfo.sections.map((section, sectionIndex) => {
//     const images = section.images.map((imageInfo, imageIndex) => {
//       return (
//         <Style.WorkImage
//           imageName={imageInfo.imageName}
//           key={`image.${section}.${imageIndex}`}
//           width="100%"
//           maxWidth="500px"
//         ></Style.WorkImage>
//       )
//     })
//     return (
//       <div key={sectionIndex}>
//         <H3>{section.heading}</H3>
//         <Paragraph textAlign="left" paddingBottom="20px">
//           {section.subheading}
//         </Paragraph>
//         {images}
//       </div>
//     )
//   })
//   return (
//     <Style.Layout location={window.location}>
//       <SEO title={name}></SEO>
//       {icon}
//       <Style.H1>{name}</Style.H1>
//       {sections}
//       {appStore}
//     </Style.Layout>
//   )
//   // const sections = props.map((section, i) => {
//   //   const heading = !section.heading ? undefined : <h1>{section.heading}</h1>
//   //   return <div key={i}>{heading}</div>
//   // })
//   // return sections
// }

// // Can do a non static query because this template file was used in
// // gatsby-node createPages function
// export const query = graphql`
//   query($workId: String!) {
//     projectsJson(id: { eq: $workId }) {
//       name
//       id
//     }
//   }
// `

const workDetail = (props: IWorkSectionProps) => {
  // remove the forward slash
  const { name, id } = props.data.projectsJson
  const workInfo = workInfoHelper(id)
  if (!workInfo) {
    // should log this
    return <></>
  }
  const appStore = workInfo.appStoreLink ? (
    <Style.AppStoreButton href={workInfo.appStoreLink} target="_blank">
      <AppStoreIconWhite />
    </Style.AppStoreButton>
  ) : null
  const icon = <Style.Icon imageName={workInfo.imageName} />
  const sections = workInfo.sections.map((section, sectionIndex) => {
    const images = section.images.map((imageInfo, imageIndex) => {
      return (
        <Style.WorkImage
          imageName={imageInfo.imageName}
          key={`image.${section}.${imageIndex}`}
          width="100%"
          maxWidth="500px"
        ></Style.WorkImage>
      )
    })
    return (
      <div key={sectionIndex}>
        <H3>{section.heading}</H3>
        <Paragraph textAlign="left" paddingBottom="20px">
          {section.subheading}
        </Paragraph>
        {images}
      </div>
    )
  })
  const description = `Information for AJ Bartocci's work with ${props.data.projectsJson.name}`
  return (
    <Location>
      {({ location }) => {
        return (
          <Style.Layout location={location}>
            <SEO title={name} description={description}></SEO>
            {icon}
            <Style.H1>{name}</Style.H1>
            {sections}
            {appStore}
          </Style.Layout>
        )
      }}
    </Location>
  )
  // const sections = props.map((section, i) => {
  //   const heading = !section.heading ? undefined : <h1>{section.heading}</h1>
  //   return <div key={i}>{heading}</div>
  // })
  // return sections
}

// Can do a non static query because this template file was used in
// gatsby-node createPages function
export const query = graphql`
  query($workId: String!) {
    projectsJson(id: { eq: $workId }) {
      name
      id
    }
  }
`

export default workDetail
